import Scene from './components/Scene';
import "./App.css"
function App() {
  return (
    <div >
     <Scene/>
    </div>
  );
}

export default App;
