import React from "react";
import { Vector3, HemisphericLight, MeshBuilder, ArcRotateCamera, SceneLoader } from "@babylonjs/core";
import "@babylonjs/loaders/glTF/2.0";
import "@babylonjs/core/Helpers/sceneHelpers";
import SceneComponent from "./SceneComponent"; // uses above component in the same directory
import damagedHelmet from "../assets/DamagedHelmet.glb";
import forest from "../assets/forest.env";

let helmet;
let camera;

const onSceneReady = async (scene) => {
  const canvas = scene.getEngine().getRenderingCanvas();

  // Create a free camera positioned relative to the box
  const camera = new ArcRotateCamera("camera", 2, 1, 10, new Vector3(0, 0, 0));
  camera.attachControl(canvas, true);

  scene.createDefaultEnvironment({
    environmentTexture: forest,
    skyboxTexture: forest
  });

  const minZoom = 2;  // Minimum allowed radius (distance from target)
  const maxZoom = 10; // Maximum allowed radius

  camera.lowerRadiusLimit = minZoom;
  camera.upperRadiusLimit = maxZoom;

  // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
  const light = new HemisphericLight("light", new Vector3(0, 4, 0), scene);

  // Default intensity is 1. Let's dim the light a small amount
  light.intensity = 1;

  
   helmet = await SceneLoader.ImportMeshAsync("", damagedHelmet, '', scene)
  helmet.meshes[1].position.y = 2;
  helmet.meshes[1].scaling = new Vector3(2, 2, 2);
  console.log(helmet,"helmets")
  scene.onBeforeRenderObservable.add(() => {
    helmet.meshes[0].rotate(new Vector3(0, 1, 0), 0.006);
  });
  camera.setTarget(helmet.meshes[1]);
};

/**
 * Will run on every frame render. We are spinning the box on the y-axis.
 */
const onRender = (scene) => {
  if (helmet && camera) {
    
  }
};

export default () => (
  <div style={{ width: "100%" }}>
    <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
  </div>
);
